import React, { useState } from 'react'
import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'
import { Button, useConfettis } from 'nzk-react-components'
import { Howl } from 'howler'
import arrowPng from './assets/arrow.png'
import framePng from './assets/frame.png'
import wheelPng from './assets/wheel.png'
import { GiftType } from '../types'
import successSound from '../assets/success.mp3'
import spinSound from '../assets/spin.mp3'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > .button {
    margin-top: 20px;
  }
`

const SpinningWheel = styled.div`
  position: relative;
  height: 400px;
  width: 400px;
  > * {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-size: contain;
  }
  #frame {
    background-image: url("${framePng}");
  }
  #wheel {
    background-image: url("${wheelPng}");
  }
  #arrow {
    background-image: url("${arrowPng}");
  }
`

interface IProps {
  onSpinned: (gift: 'CREATIVITY' | 'BOOK1' | 'STORY_WRITING') => void
}
const GiftSpinner = (props: IProps) => {
  const [angle, setAngle] = useState(0)
  const { ANIMATIONS } = useConfettis()
  const [gift, setGift] = useState<GiftType | null>(null)
  const [spinned, setSpinned] = useState(false)

  const spin = () => {
    setSpinned(true)
    const s = new Howl({ src: spinSound })
    s.play()
    const MIN_SPINS = 25
    // const MAX_SPINS = 30
    const nbSpins = MIN_SPINS // + Math.floor(Math.random() * (MAX_SPINS - MIN_SPINS))

    const angle = nbSpins * 2 * Math.PI

    const OUTCOMES: GiftType[] = ['CREATIVITY', 'BOOK1', 'STORY_WRITING']
    const RANGES = [
      Math.PI + Math.PI / 6,
      Math.PI + 2 * Math.PI / 3,
      Math.PI + 4 * Math.PI / 3,
    ]
    const random = Math.random()
    let index
    if (random < 0.1) index = 0
    else if (random > 0.8) index = 2
    else index = 1
    const outcome = OUTCOMES[index]
    const extraAngle = Math.PI / 10 + RANGES[index] + Math.random() * (2 * Math.PI / 3 - (2 * Math.PI / 10))
    setGift(outcome)
    setAngle(angle + extraAngle)
  }
  
  const onSpinOver = () => {
    if (gift) {
      const s = new Howl({ src: successSound })
      s.play()
      ANIMATIONS.fireworks({
        durationInMs: 8000,
        confettiOptions: {}
      })
      props.onSpinned(gift)
    }
  }

  const wheelSpring = useSpring({
    rotate: `${angle}rad`,
    config: { mass: 50, tension: 200, friction: 200, precision: 0.001 },
    onRest: () => {
      onSpinOver()
    }
  })

  return <Wrapper>
    <SpinningWheel>
      <div id='frame' />
      <animated.div id='wheel' style={wheelSpring} />
      <div id='arrow' />
    </SpinningWheel>
    <div className='button'>
      { !spinned && <Button size='regular' theme='primary' onClick={() => spin()}>Spin to win</Button> }
      { /* <Button size='x-small' theme='red' onClick={() => setAngle(0)}>Reset</Button> */ }
    </div>
  </Wrapper>
}

export default GiftSpinner
