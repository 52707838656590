import PageLoader from 'components/UI/PageLoader'
import { Button } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { COLORS } from '../../constants'
import posterImg from './assets/poster.png'

const Wrapper = styled.div`
  color: #fff;
  padding-top: 40px;
`

const ThanksSection = styled.div`
  position: relative;
  background-color: ${COLORS.lightPurple};
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > .title {
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
    font-family: 'Rammetto One';
    em {
      font-style: normal;
      color: #02C5ED;
    }
  }
  > .content {
    text-align: center;
    margin-top: 20px;
    > p {
      margin: 0;
      line-height: 30px;
    }
  }
`

const FeedbackSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 638px;
  padding: 0 20px;

  > .title {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Rammetto One';
  }
  textarea {
    resize: none;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    line-height: 24px;
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  > :first-child {
    margin-bottom: 30px;
  }
`

interface IProps {
  onStaySubscribed: () => void
  onCancelRequest: (feedback: string) => Promise<void>
}

export const Feedback = (props: IProps) => {
  const [submitting, setSubmitting] = useState(false)
  const [value, setValue] = useState('')

  return <>
  { submitting && <PageLoader color='#fff' size={90} /> }
  <Wrapper>
    <ThanksSection>
      <div className='title'>Hit <em>Submit & Cancel</em> below to end your subscription.<br />
      Thank you for using Night Zookeeper.</div>
      <img src={posterImg} alt='NZK Banner' />
      <div className='content'>
        <p>We're sorry to see you go!</p>
        <p>To help us improve our program, we'd love to hear</p>
        <p>your reasons for cancelling, or any other feedback you have.</p>
      </div>
    </ThanksSection>
    <FeedbackSection>
      <div className='title'>Your Feedback</div>
      <textarea placeholder='Your feedback' value={value} onChange={(e) => setValue(e.target.value)} />
    </FeedbackSection>
    <Buttons>
      <Button size='regular' theme='primary' disabled={submitting} onClick={async () => {
        if (submitting) return
        setSubmitting(true)
        await props.onCancelRequest(value)
        setSubmitting(false)
      }}>Submit & Cancel</Button>
    </Buttons>
  </Wrapper>
  </>
}
