import React, { useEffect, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import styled from 'styled-components'
import { Button } from 'nzk-react-components'
import { GiftType } from '../types'
import { GIFTS } from '..'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled(animated.div)`
  position: absolute;
  background-color: #341644;
  color: #fff;
  padding: 30px;
  border-radius: 20px;
  min-width: 400px;
  text-align: center;
  > .title {
    text-align: center;
    width: 100%;
    font-size: 30px;
    line-height: 40px;
    font-family: 'Rammetto One';
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-50%);
    text-shadow: 0 5px 0 #afafaf, 0 11px 0 rgba(0,0,0,0.4);
  }
  > .gift {
    img {
      max-width: 400px;
    }
  }
  > .button {
  }
`

interface IProps {
  gift: GiftType
  onDownload: () => void
}

const GiftModal = (props: IProps) => {
  const [appeared, setAppeared] = useState(false)

  const [spring, api] = useSpring(() => ({
    y: window.innerHeight,
  }))

  const GIFT = GIFTS[props.gift]

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      if (typeof window !== 'undefined') {
        document.body.style.overflow = 'auto'
      }
    }
  })

  useEffect(() => {
    if (!appeared) {
      api.start({
        y: window.innerHeight
      })
      setTimeout(() => {
        setAppeared(true)
      }, 1000)
    } else {
      api.start({
        y: 0
      })
    }
  }, [appeared])

  return <Overlay onClick={() => setAppeared(false)}>
    <Wrapper style={spring}>
      <div className='title'>Congratulations!</div>
      <div className='gift'>
        <img src={GIFT.thumbnail} alt='Gift' />
      </div>
      <div className='button'>
        <Button theme='primary' size='regular' onClick={() => props.onDownload()}>Download</Button>
      </div>
    </Wrapper>
  </Overlay>
}

export default GiftModal
