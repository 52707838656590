import React, { useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Loader from 'components/UI/Loader'
import { useCurrentUserState } from 'state/CurrentUserState'
import { RetentionOfferCancelScreen } from 'modules/retention/components'
import { CancelPageState } from './index.state'
import { Feedback, Gift, Offer } from './screens'

const Wrapper = styled.div`
  margin: 0 auto;
  padding-bottom: 90px;
`

const Content = styled.div`
  margin-top: 30px;
  > div {
    margin-bottom: 25px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const DEBUG = false

const CancelPage = () => {
  const {
    step,
    setStep,
    submit,
    loadingData,
    cancellationFormData,
    setCancellationFormData,
  } = CancelPageState.useContainer()
  const { currentUser } = useCurrentUserState()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0 })
    }
  }, [step])

  const onCancel = async (reason: string) => {
    await submit(reason)
  }

  const onStaySubscribed = (target?: string) => {
    navigate(target || '/account')
  }

  if (!currentUser) return null
  return (
    <Wrapper>
      {loadingData ? (
        <div style={{ paddingTop: '100px' }}>
          <Loader color="#fff" />
        </div>
      ) : (
        <Content>
          {step === 0 && (
            <RetentionOfferCancelScreen
              onClaimed={onStaySubscribed}
              onCancelRequest={() => setStep((s) => s + 1)}
            />
          )}
          {/* step === 1 && <Program onStaySubscribed={onStaySubscribed} onCancelRequest={() => setStep(s => s + 1)} /> */}
          {/* step === 1 && <PersonalizeFaq onStaySubscribed={() => onStaySubscribed('/students?deepLink=/student/{{username}}?page=settings')} onCancelRequest={() => setStep(s => s + 1)} /> */}
          {step === 1 && (
            <Offer onCancelRequest={() => setStep((s) => s + 1)} />
          )}
          {step === 2 && (
            <Feedback
              onStaySubscribed={onStaySubscribed}
              onCancelRequest={async (feedback) => {
                setCancellationFormData({
                  ...cancellationFormData,
                  reason: feedback,
                })
                if (DEBUG) {
                  await new Promise((resolve) => {
                    setTimeout(resolve, 2000)
                  })
                } else {
                  await onCancel(feedback)
                }
                setStep((s) => s + 1)
              }}
            />
          )}
          {step === 3 && <Gift />}
        </Content>
      )}
    </Wrapper>
  )
}

CancelPage.propTypes = {}

CancelPage.defaultProps = {}

export default (props) => (
  <CancelPageState.Provider>
    <CancelPage {...props} />
  </CancelPageState.Provider>
)
