import { useMutation, useQuery } from '@apollo/client'
import { Button } from 'nzk-react-components'
import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { getFormattedAmount } from 'components/pages/SubscribePage/utils'
import Loader from 'components/UI/Loader'
import GET_DATA from './getData.graphql'
import samImg from './assets/sam.png'
import { COLORS } from '../../constants'
import APPLY_DISCOUNT from './applyDiscount.graphql'
import { IOffer } from './types'
import OfferTaken from './OfferTaken'

const Wrapper = styled.div`
  color: #fff;
  > .padded {
    padding: 0 20px;
  }
  .plan-section {
    max-width: 640px;
    margin: 0 auto;
    margin-bottom: 50px;
    background-color: ${COLORS.lightPurple};
    padding: 18px 28px 28px 28px;
    border-radius: 20px;
    text-align: center;
    > .title {
      font-family: 'Rammetto One';
      margin-bottom: 6px;
    }
  }
  
  > .quotes-section {
    position: relative;
    background-color: ${COLORS.lightPurple};
    padding-top: 30px;
    padding-bottom: 100px;
    > .sub-title {
    font-family: 'Rammetto One';
    text-align: center;
    margin-bottom: 30px;
    text-shadow: 0 3px 0 #afafaf, 0 5px 0 rgba(0,0,0,0.4);
  }
    > .title {
      text-align: center;
      width: 100%;
      font-size: 30px;
      line-height: 40px;
      font-family: 'Rammetto One';
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-50%);
      text-shadow: 0 5px 0 #afafaf, 0 11px 0 rgba(0,0,0,0.4);
    }
  }
`

const Quotes = styled.div`
  display: flex;
  max-width: 960px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
    > * { margin-bottom: 30px; }
    > :last-child { margin-bottom: 0; }
  }
`

const Quote = styled.div<{ featured: boolean }>`
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  width: 281px;
  text-align: center;
  color: #000;
  ${props => props.featured && css`
    border: 4px solid #FE7A48;
  `}
  padding: 23px 20px 30px 20px;
  > .discount {
    position: absolute;
    top: -20px;
    z-index: 2;
    height: 80px;
    width: 80px;
    right: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 200px;

    font-size: 20px;
    background-color: #FE7A48;
    font-family: 'Rammetto One';
    box-shadow: 0 5px 0 #C7582E, 0 10px 0 rgba(0,0,0,0.4);
    > .label {
      transform: rotate(10deg);
      text-shadow: 0 3px 0 #afafaf, 0 5px 0 rgba(0,0,0,0.4);
    }
  }
  > .title {
    font-size: 30px;
    color: #5D5D5D;
    margin-bottom: 10px;
  }
  > .note {
    color: #FE6F38;
    font-size: 15px;
    margin-bottom: 17px;
  }
  > .old-price {
    font-size: 20px;
    margin-bottom: 5px;
    text-decoration: line-through;
  }
  > .new-price {
    font-size: 30px;
    margin-bottom: 6px;
  }
  > .payment-info {
    font-size: 12px;
    color: #555454;
    min-height: 24px;
  }
  > .button {
    margin-top: 20px;
  }
  > .sam {
    position: relative;
    height: 153px;
    width: 100%;

    > :first-child {
      position: absolute;
      height: 223px;
      width: 100%;
      bottom: calc(-46px - 34px);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom center;
      background-image: url("${samImg}");
    }
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
  > :first-child {
    margin-bottom: 30px;
  }
`

interface IProps {
  onCancelRequest: () => void
}

export const Offer = (props: IProps) => {
  const { data, loading } = useQuery(GET_DATA, { fetchPolicy: 'network-only' })
  const [applyDiscount, { loading: applying }] = useMutation(APPLY_DISCOUNT)
  const [appliedOffer, setAppliedOffer] = useState<IOffer>()

  const quotes = useMemo(() => {
    if (data?.me?.subscription?.quotes?.length > 0) {
      return data.me.subscription.quotes.map(q => {
        const quote: any = {
          title: 'Monthly',
          planId: q.plan.id,
          discount: q.maxDiscount * 100,
          newMonthlyPrice: Intl.NumberFormat('en-us', { style: 'currency', currency: q.plan.currency })
            .format(getFormattedAmount(q.newAmount / q.basePlan.interval_count, q.plan.currency)),
          oldMonthlyPrice: Intl.NumberFormat('en-us', { style: 'currency', currency: q.plan.currency })
            .format(getFormattedAmount(q.basePlan.amount / q.basePlan.interval_count, q.plan.currency)),
          oldPrice:Intl.NumberFormat('en-us', { style: 'currency', currency: q.plan.currency })
            .format(getFormattedAmount(q.basePlan.amount, q.plan.currency)),
          newPrice: Intl.NumberFormat('en-us', { style: 'currency', currency: q.plan.currency })
            .format(getFormattedAmount(q.newAmount, q.plan.currency)),
        }
        if (q.basePlan.interval === 'month' && q.basePlan.interval_count === 1) {
          return {
            ...quote,
            title: 'Monthly',
            index: 0
          }
        }
        if (q.basePlan.interval === 'month') {
          return {
            ...quote,
            title: '3 Months',
            info: `Taken as a single payment of ${quote.newPrice}`,
            index: 2
          }
        }
        return {
          ...quote,
          title: 'Yearly',
          info: `Taken as a single payment of ${quote.newPrice}`,
          index: 1,
          featured: true,
        }
      }).sort((a, b) => a.index - b.index)
    }
    return []
  }, [data])

  const currentPlanLabel = useMemo(() => {
    if (!data?.me?.subscription) return null
    const subscription = data?.me?.subscription
    const price = Intl.NumberFormat('en-us', { style: 'currency', currency: subscription.plan.currency })
      .format(getFormattedAmount(subscription.plan.amount, subscription.plan.currency))
    let recurring = 'Yearly'
    if (subscription.plan.interval === 'month') {
      if (subscription.plan.interval_count === 1) recurring = 'Monthly'
      else recurring = 'Quarterly'
    }
    return `${recurring} ${price} payment (next payment ${Intl.DateTimeFormat('en-us', { month: 'long', day: '2-digit', year: 'numeric' }).format(data.me.subscription.current_period_end * 1000)})`
  }, [data])

  useEffect(() => {
    if (data && quotes.length === 0) {
      props.onCancelRequest()
    }
  }, [quotes, data])

  const onApplyDiscount = async (quote: IOffer) => {
    if (applying) return
    await applyDiscount({ variables: { plan: quote.planId } })
    // navigate('/account?forceRefetch=true')
    setAppliedOffer(quote)
  }

  if (loading) return <Loader color='#fff' size={50} minHeight={500} />
  if (appliedOffer) return <OfferTaken offer={appliedOffer} subscriptionStart={data?.me?.subscription?.current_period_end ? Math.max(Date.now(), data.me.subscription.current_period_end * 1000) : undefined} />
  return <Wrapper>
    <div className='padded'>
      <div className='plan-section'>
        <div className='title'>
          Your Current Plan:
        </div>
        <div className='plan'>
          {currentPlanLabel}
        </div>
      </div>
    </div>
    <div className='quotes-section'>
      <div className='title'>Are you sure?</div>
      <div className='sub-title'>Special discounts just for you</div>
      <Quotes>
        {
          quotes.map(q => <Quote key={q.planId} featured={q.featured}>
            <div className='discount'>
              <div className='label'>
                <div>{q.discount}<span>%</span></div><div>Off</div>
              </div>
            </div>
            <div className='title'>{q.title}</div>
            <div className='note'>Cancel Anytime Online</div>
            <div className='new-price'>{q.newMonthlyPrice}</div>
            <div className='old-price'>{q.oldMonthlyPrice}</div>
            <div className='payment-info'>{q.info}</div>
            <div className='button'>
              <Button
                theme={q.featured ? 'confirm' : 'primary'}
                size={q.featured ? 'regular' : 'small'}
                disabled={applying}
                onClick={() => onApplyDiscount(q)}
              >Get Offer</Button>
            </div>
            { q.featured && <div className='sam'><div /></div> }
          </Quote>)
        }
      </Quotes>
    </div>

    <Buttons>
      <Button size='small' theme='primary' onClick={props.onCancelRequest}>Still want to cancel</Button>
    </Buttons>
  </Wrapper>
}
