import { Avatar, Button, useConfettis } from 'nzk-react-components'
import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useCurrentUserState } from 'state/CurrentUserState'
import styled from 'styled-components'
import { COLORS } from '../../constants'
import { IOffer } from './types'

const Wrapper = styled.div`
  color: #fff;
`

const Title = styled.div`
  text-align: center;
  font-size: 30px;
  line-height: 40px;
  font-family: 'Rammetto One';
  text-shadow: 0 5px 0 #afafaf, 0 11px 0 rgba(0,0,0,0.4);
  margin-bottom: 60px;
`

const Box = styled.div`
  max-width: 400px;
  margin: 0 auto;
  background-color: ${COLORS.lightPurple};
  box-shadow: 0 6px 0 rgba(0,0,0,0.4);
  padding: 20px;
  border-radius: 20px;
  > * { margin-bottom: 8px; }
  > :last-child { margin-bottom: 0; }
  > .title {
    font-family: 'Rammetto One';
    margin-bottom: 16px;
    text-shadow: 0 3px 0 #afafaf, 0 5px 0 rgba(0,0,0,0.3);
  }
`

const NewSubscription = styled(Box)`
  > div {
    > span:first-child {
      font-weight: bold;
      margin-right: 8px;
    }
  }
  margin-bottom: 30px;
`

const Children = styled.div`
  display: flex;
  overflow: auto;
  padding-bottom: 20px;
  > .child {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5px;
    font-size: 10px;
    > :first-child {
      margin-bottom: 3px;
    }
  }
`

const Buttons = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`

interface IProps {
  offer: IOffer
  subscriptionStart?: number
}

const OfferTaken = (props: IProps) => {
  const { currentUser } = useCurrentUserState()
  const { ANIMATIONS } = useConfettis()

  useEffect(() => {
    ANIMATIONS.fireworks({ durationInMs: 4000, confettiOptions: {} })
  }, [])

  return <Wrapper>
    <Title>Thank you!</Title>
    <NewSubscription>
      <div className='title'>New subscription details</div>
      <div>
        <span>Plan:</span>
        <span>{props.offer.title} plan</span>
      </div>
      <div>
        <span>Next payment date:</span>
        <span>{Intl.DateTimeFormat('en-us', {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        }).format(props.subscriptionStart)}</span>
      </div>
      <div>
        <span>Next payment:</span>
        <span style={{ fontSize: '14px', marginRight: '8px' }}><s>{props.offer.oldPrice}</s></span>
        <span>{props.offer.newPrice}</span>
      </div>
    </NewSubscription>
    <Box>
      <div className='title'>Your children</div>
      <Children>
        {
          currentUser?.children.map(c => <div className='child'>
            <Avatar user={{ ...c, type: 'student' }} size='80px' />
            <div>{c.username}</div>
          </div>)
        }
      </Children>
    </Box>
    <Buttons>
      <Button size='regular' theme='primary' onClick={() => navigate('/account?forceRefetch=true')}>Back</Button>
    </Buttons>
  </Wrapper>
}

export default OfferTaken
