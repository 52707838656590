import { gql, useApolloClient, useQuery } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import CANCEL_PARENT_ACCOUNT from './graphql/cancelParentAccount.graphql'

export const useCancelPageState = () => {
  const client = useApolloClient()
  const [step, setStep] = useState(0)

  const { loading: loadingData, data } = useQuery(gql`
    query getChildrenSubscribed {
      me {
        _id
        children {
          _id
          starTutoring {
            _id
          }
        }
      }
    }
  `, {
    fetchPolicy: 'network-only'
  })

  const [loading, setLoading] = useState(false)

  const children = useMemo(() => {
    // return [...(data?.me.children || []), ...(data?.me.children || []), ...(data?.me.children || [])]
    return data?.me.children || []
  }, [data])

  const hasAtLeastOneSubscription = useMemo(() => {
    return children.filter(c => c.starTutoring?._id).length > 0
  }, [children])

  useEffect(() => {
    if (hasAtLeastOneSubscription) {
      setStep(1)
    }
  }, [hasAtLeastOneSubscription])


  const [cancellationFormData, setCancellationFormData] = useState<{ reason: string | null}>({
    reason: null
  })

  useEffect(() => {
    const reason = localStorage.getItem('cancellationReason') || ''
    setCancellationFormData({
      ...cancellationFormData,
      reason
    })
    // eslint-disable-next-line
  }, [])

  const cacheCancellationReason = (reason) => {
    localStorage.setItem('cancellationReason', reason)
  }

  useEffect(() => {
    cacheCancellationReason(cancellationFormData.reason)
  }, [cancellationFormData.reason])


  const submit = async (reason: string) => {
    setLoading(true)
    await client.mutate({
      mutation: CANCEL_PARENT_ACCOUNT,
      variables: {
        feedback: reason
      }
    })
    cacheCancellationReason('')
    setLoading(false)
  }

  return {
    loadingData,
    hasAtLeastOneSubscription,
    cancellationFormData,
    setCancellationFormData,
    step,
    setStep,
    loading,
    submit
  }
}

export const CancelPageState = createContainer(useCancelPageState)
