import React, { useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import GiftModal from './GiftModal'
import GiftSpinner from './GiftSpinner'
import book1Img from './assets/book1.png'
import writingImg from './assets/writing.png'
import creativityImg from './assets/creativity.png'
import { GiftType } from './types'
import { COLORS } from '../../constants'

export const GIFTS: { [key in GiftType]: {
  thumbnail: string,
  downloadLink: string,
  title: string
} } = {
  'BOOK1': {
    title: '',
    thumbnail: book1Img,
    downloadLink: 'https://dashboard.nightzookeeper.com/resources/book-and-activities.pdf',
  },
  'CREATIVITY': {
    title: '',
    thumbnail: creativityImg,
    downloadLink: 'https://dashboard.nightzookeeper.com/resources/creativity-challenges.pdf',
  },
  'STORY_WRITING': {
    title: '',
    thumbnail: writingImg,
    downloadLink: 'https://dashboard.nightzookeeper.com/resources/how-to-write-a-story.pdf',
  }
}

const Wrapper = styled.div`

`

const Title = styled.div`
  text-align: center;
  width: 100%;
  font-size: 30px;
  color: #fff;
  margin-bottom: 30px;
  text-align: center;
  line-height: 40px;
  font-family: 'Rammetto One';
  text-shadow: 0 5px 0 #afafaf, 0 11px 0 rgba(0,0,0,0.4);
`

const Gifts = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 960px;
  margin: 0 auto;
  margin-top: 40px;
`

const GiftItem = styled.div`
  background-color: ${COLORS.lightPurple};
  width: 280px;
  border-radius: 20px;
  box-shadow: 0 8px 0 rgba(0,0,0,0.3), 0 -3px 0 #531D75;

`

export const Gift = () => {
  const [gift, setGift] = useState<GiftType | null>(null)

  const onSpinned = (gift: GiftType) => {
    setGift(gift)
  }

  const onDownload = () => {
    if (gift) {
      const { downloadLink } = GIFTS[gift]
      window.open(downloadLink, '_blank')
      navigate('/account?forceRefetch=true')
    }
  }

  return <Wrapper>
    <Title>Here's a free gift on us!</Title>
    <GiftSpinner onSpinned={onSpinned} />
    { gift && <GiftModal gift={gift} onDownload={onDownload} /> }
    <Gifts>
      <GiftItem><img src={GIFTS.BOOK1.thumbnail} alt={GIFTS.BOOK1.title} /></GiftItem>
      <GiftItem><img src={GIFTS.CREATIVITY.thumbnail} alt={GIFTS.CREATIVITY.title} /></GiftItem>
      <GiftItem><img src={GIFTS.STORY_WRITING.thumbnail} alt={GIFTS.STORY_WRITING.title} /></GiftItem>
    </Gifts>
  </Wrapper>
}
