import Video from 'components/pages/StarTutoring/components/Video'
import RatioBox from 'components/UI/RatioBox'
import { AdvancedTutoring, Button, Target } from 'nzk-react-components'
import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

const Wrapper = styled.div`
  color: #fff;
  padding: 0 20px;
  > .video {
    border-radius: 20px;
    overflow: hidden;
    max-width: 640px;
    margin: 0 auto;
  }
  > .learn-more {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
`

const Title = styled.div`
  text-align: center;
  width: 100%;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  margin-bottom: 30px;
  font-family: 'Rammetto One';
  text-shadow: 0 5px 0 #afafaf, 0 11px 0 rgba(0,0,0,0.4);
`

const Benefits = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 40px;
  > .title {
    font-family: 'Rammetto One';
    text-align: center;
    margin-bottom: 20px;
  }

  > .benefits {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    @media (max-width: 990px) {
      grid-template-columns: 1fr;
      > * {
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

`

const Benefit = styled.div`
  position: relative;
  width: 100%;
  margin-top: 30px;
  background-color: #341644;
  padding: 100px 30px 30px 30px;
  border-radius: 30px;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  > :first-child {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -20%);
    display: flex;
    justify-content: center;
  }
  > .features--content {
  }

`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  > :first-child {
    margin-bottom: 30px;
  }
`

interface IProps {
  onCancelRequest: () => void
}

export const StarTutoring = (props: IProps) => {
  return <Wrapper>
    <Title>
      <div>Before you go...</div>
      <div>Try Advanced Tutoring for Free!</div>
    </Title>
    <div className='video'>
      <RatioBox ratio='16:9'>
        <Video src='https://vimeo.com/758038648' />
      </RatioBox>
    </div>
    <div className='learn-more'>
      <Button size='regular' theme='confirm' onClick={() => navigate('/advanced-tutoring') }>Learn more</Button>
    </div>
    <Benefits>
      <div className='title'>The Benefits</div>
      <div className='benefits'>
        <Benefit>
          <Button size='x-large' theme='red' round>
            <Target />
          </Button>
          <div className='features--content'>
            <p>In-depth, annotated, and personalised feedback on special writing assignments from a dedicated tutor.</p>
          </div>
        </Benefit>
        <Benefit>
          <Button size='x-large' theme='purple' round>
            <AdvancedTutoring />
          </Button>
          <div className='features--content'>
            <p>A flexible tutoring add-on to nightzookeeper.com, that can be turned on whenever your child needs extra writing practice.</p>
          </div>
        </Benefit>
      </div>
    </Benefits>
    <Buttons>
      <Button size='small' theme='primary' onClick={props.onCancelRequest}>Still want to cancel</Button>
    </Buttons>
  </Wrapper>
}
