import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { Button, Checkmark, IconButton } from 'nzk-react-components'
import { gql, useMutation, useQuery } from '@apollo/client'
import CLAIM_RETENTION_OFFER from './claimRetentionOffer.graphql'

const Wrapper = styled.div`
  background-image: url('/images/background-6.jpg');
  background-size: cover;
  background-position: center;
  padding: 20px 0;
  height: 100%;
  margin-top: -30px;
  margin-bottom: -90px !important;
  padding-bottom: 90px;
`

const Content = styled.div`
  color: #fff;
  max-width: 540px;
  margin: 0 auto;
  padding: 0 20px;
  .offer--title {
    font-family: 'Rammetto One';
    font-size: 1.5em;
    text-align: center;
    text-shadow: 0px .1em #afafaf, 0px .2em rgba(0, 0, 0, 0.3);
  }
  .offer--sub-title {
    font-family: 'Rammetto One';
    color: #ffed00;
    font-size: 1em;
    text-align: center;
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    .item--image {
      width: 240px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
    }

    .item--content {
      max-width: 240px;
      .item--title {
        font-family: 'Rammetto One';
        font-size: 1.4em;
        line-height: 1.2;
        margin-bottom: 0.3em;
        text-shadow: 0px .1em #afafaf, 0px .2em rgba(0, 0, 0, 0.3);
      }
    
      .item--sub-title {
        font-family: 'Rammetto One';
        font-size: .8em;
      }

      .item--description {
        font-size: 0.7em;
      }
    }
  }

  @media (max-width: 540px) {
    .item {
      flex-direction: column;
      width: 100%;
      .item--content {
        max-width: none;
      }
      .item--image {
        margin-bottom: 20px;
        margin-right: 0;
        
      }
      text-align: center;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  > :first-child {
    margin-bottom: 30px;
  }
`

interface IProps {
  onClaimed: () => void
  onCancelRequest: () => void
}
export const RetentionOfferCancelScreen = (props: IProps) => {
  const [claimed, setClaimed] = useState(false)
  const [offerIndex, setOfferIndex] = useState(0)

  const query = useQuery(gql`
    query getOffers {
      me {
        _id
        retention_offers(filters: { claimOn: "request-cancel" }) {
          id
          claimed
          canClaim
          available
          content {
            title
            subTitle
            worth
            items {
              imageUrl
              title
              subTitle
              description
            }
          }
        }
      }
    }
  `, {
    fetchPolicy: 'network-only'
  })

  const offers = useMemo(() => {
    return query.data?.me?.retention_offers?.filter(r => (r.claimed && !r.available) || r.canClaim)
      // Show claimed offers first
      .sort((a, b) => {
        return b.claimed - a.claimed
      })
  }, [query])

  const offer = useMemo(() => {
    if (!offers) return null
    return offers[offerIndex]
  }, [offers, offerIndex])

  const isLastOffer = useMemo(() => {
    return (offers || []).length - 1 === offerIndex
  }, [offers, offerIndex])

  const [claimOffer, { loading }] = useMutation(CLAIM_RETENTION_OFFER, {
    variables: {
      id: offer?.id,
      on: 'request-cancel'
    }
  })

  useEffect(() => {
    if (!offer && !query.loading) {
      props.onCancelRequest()
    }
  }, [offer, query.loading])

  const submit = async () => {
    if (loading || claimed) return
    const { data } = await claimOffer()
    if (data?.retention_claimOffer) {
      setClaimed(true)
      navigate(`/offers/${offer.id}`)
      // props.onClaimed()
    } else {
      props.onClaimed()
    }
  }

  const onDeny = () => {
    if (isLastOffer) {
      props.onCancelRequest()
    } else {
      setOfferIndex(s => s + 1)
    }
  }

  if (!offer || query.loading) return null
  return <Wrapper>
    <Content>
      <h1 className='offer--title'>{offer.content?.title}</h1>
      <h2 className='offer--sub-title'>{offer.content?.subTitle}</h2>
      { offer.content?.items && <div className='items'>
        {
          offer.content.items.map((item, i) => {
            return <div key={i} className='item'>
              <div className='item--image'>
                <img src={item.imageUrl} alt={item.title} />
              </div>
              <div className='item--content'>
                <div className='item--title'>{item.title}</div>
                <div className='item--sub-title'>{item.subTitle}</div>
                <div className='item--description'>{item.description}</div>
              </div>
            </div>
          })
        }
      </div> }
      <div dangerouslySetInnerHTML={{ __html: offer.content?.description }} />
      <Buttons>
        { offer?.canClaim && <IconButton theme='confirm' icon={<Checkmark />} size='regular' disabled={loading} onClick={submit}>Claim</IconButton> }
        { offer?.claimed && <IconButton theme='confirm' icon={<Checkmark />} size='regular' onClick={props.onClaimed}>Keep subscribing</IconButton> }
        <Button size='small' theme='primary' onClick={onDeny}>Still want to cancel</Button>
      </Buttons>
    </Content>
  </Wrapper>
}
