import React from 'react'
import styled, { css } from 'styled-components'
import { IconButton, Button, Checkmark } from 'nzk-react-components'
import difficultyImage from './assets/difficulty.png'
import manageImg from './assets/manage.png'
import blockImg from './assets/block.png'
import kidSettingsImage from './assets/kid-settings-1.png'
import assignmentsImg from './assets/assignments.png'
import { COLORS } from '../../constants'

const Wrapper = styled.div`
  color: #fff;
  > .padded {
    padding: 0 20px;
  }
`

const ELEMENTS = [
  { thumbnail: difficultyImage, title: "Need to adjust the difficulty level?", copy: "Changing your child’s age increases or decreases the difficulty of the content." },
  { thumbnail: manageImg, title: "Typing skills not quite there yet?", copy: "Switching to easy mode will prioritize non-typing activities in your child’s curriculum." },
  { thumbnail: blockImg, title: "Want your child to focus more on writing prompts and lessons?", copy: "Turn off all word games & the Night Zoo - and then back on again whenever you want so they can focus on their learning journey." },
]

const TitleBox = styled.div`
  text-align: center;
  width: 100%;
  font-size: 30px;
  line-height: 40px;
  font-family: 'Rammetto One';
  margin-bottom: 30px;
  text-shadow: 0 5px 0 #afafaf, 0 11px 0 rgba(0,0,0,0.4);
`

const Elements = styled.div`
  background-color: ${COLORS.lightPurple};
  padding: 30px 30px 30px 30px;
  
  > .content {
    max-width: 960px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  color: #fff;
  @media (max-width: 934px) {
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      > * { margin-bottom: 20px; }
    }
  }
`

const Element = styled.div`
  width: 281px;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  > .thumbnail {
    background-color: #1f032e;
    border-radius: 14px;
  }
  > .title {
    font-family: 'Rammetto One';
    font-size: 16px;
    margin-top: 10px;
  }
  > .text {
    margin-top: 15px;
    font-size: 16px;
    margin-bottom: 10px;
  }
`

const ImageHero = styled.div<{ flipped?: boolean }>`
  ${(props) => props.flipped && css`
      background-color: #341644;
  `}
  > .inner {
    display: flex;
    max-width: 960px;
    margin: 0 auto;
    ${(props) => props.flipped && css`
      flex-direction: row-reverse;
    `}

    > .image {
      min-width: 480px;
      > img {
        height: 100%;
        width: 100%;
      }
    }
    > .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > .content--title {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 40px;
      }
      padding: 20px 0 50px 0px;
    }
  }


  @media (max-width: 934px) {
    > .inner {
      flex-direction: column;
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      align-items: center;
      > .image {
        max-width: 400px;
        min-width: 300px;
        width: 100%;

        display: flex;
        justify-content: center;
      }
    }
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  > :first-child {
    margin-bottom: 30px;
  }
`

interface IProps {
  onStaySubscribed: () => void
  onCancelRequest: () => void
}

export const PersonalizeFaq = (props: IProps) => {
  return <Wrapper>
    <div className='padded'>
      <TitleBox>
        <div className='title'>Before you go</div>
      </TitleBox>
    </div>
    <Elements>
      <div className='content'>
        { ELEMENTS.map(({ thumbnail, title, copy }, i) => <Element key={i}>
          <div className='thumbnail'>
            <img src={thumbnail} alt='' />
          </div>
          <div className='title'>{title}</div>
          <div className='text'>{copy}</div>
        </Element>)}
      </div>
    </Elements>
    <ImageHero>
      <div className='inner'>
        <div className='image'>
          <img src={kidSettingsImage} alt='Kid settings' />
        </div>
        <div className='content'>
          <div className='content--title'>Personalize your child's experience</div>
          <div className='content--text'>
            Simply press ‘settings’ on your child’s profile to completely personalize your child’s experience on nightzookeeper.com.
          </div>
        </div>
      </div>
    </ImageHero>
    <ImageHero flipped>
      <div className='inner'>
        <div className='image'>
          <img src={assignmentsImg} alt='Assignments' />
        </div>
        <div className='content'>
          <div className='content--title'>Assignments</div>
          <div className='content--text'>
            Remember, with ‘Assignments’ you can also send your child personalized writing lessons, or send lessons from our library.
          </div>
        </div>
      </div>
      
    </ImageHero>
    <Buttons>
      <IconButton theme='confirm' icon={<Checkmark />} size='small' onClick={props.onStaySubscribed}>Personalize the experience</IconButton>
      <Button size='small' theme='primary' onClick={props.onCancelRequest}>Still want to cancel</Button>
    </Buttons>
  </Wrapper>
}
